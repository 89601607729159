exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-commercial-brewery-gallery-js": () => import("./../../../src/pages/gallery/commercial-brewery-gallery.js" /* webpackChunkName: "component---src-pages-gallery-commercial-brewery-gallery-js" */),
  "component---src-pages-gallery-commercial-kitchen-gallery-js": () => import("./../../../src/pages/gallery/commercial-kitchen-gallery.js" /* webpackChunkName: "component---src-pages-gallery-commercial-kitchen-gallery-js" */),
  "component---src-pages-gallery-commercial-sinks-gallery-js": () => import("./../../../src/pages/gallery/commercial-sinks-gallery.js" /* webpackChunkName: "component---src-pages-gallery-commercial-sinks-gallery-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-gallery-misc-gallery-js": () => import("./../../../src/pages/gallery/misc-gallery.js" /* webpackChunkName: "component---src-pages-gallery-misc-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

